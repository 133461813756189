<template>
  <v-main app>
    <v-container v-if="loading" class="fill-height">
      <v-row align="center" no-gutters>
        <v-col class="d-flex flex-column align-center">
          <p class="mb-8">Getting artist details...</p>
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="green"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <div class="stickyHeader pb-4">
        <h1 class="font-weight-bold">
          {{ artist && artist.name }}
        </h1>
        <v-tabs align-with-title v-model="tab" @change="handleChange">
          <v-tab href="#artworks">Artworks</v-tab>
          <v-tab v-if="false" href="#resellers">Resellers</v-tab>
          <v-tab href="#bio">Bio</v-tab>
          <v-tab to="/artists">(back to artists)</v-tab>
        </v-tabs>
      </div>
      <v-tabs-items v-model="tab">
        <v-tab-item id="artworks">
          <artworks-tab :artworks="model.artworks"></artworks-tab>
        </v-tab-item>
        <v-tab-item v-if="false" id="resellers">
          <resellers-tab
            :artist="model.artist"
            :retailers="model.retailers"
          ></resellers-tab>
        </v-tab-item>
        <v-tab-item id="bio">
          <bio-tab :artist="model.artist"></bio-tab>
        </v-tab-item>
      </v-tabs-items>
      <!-- <router-view :model="model" :artist="model.artist" :retailers="model.retailers" /> -->
    </v-container>
  </v-main>
</template>

<script>
import ArtistApi from "@/api/public/artist";
import bioTab from "./Bio.vue";
import artworksTab from "./Artworks.vue";
import resellersTab from "./Retailers.vue";
export default {
  metaInfo () {
    return { title: `${this.title}`}
  },
  components: {
    bioTab,
    artworksTab,
    resellersTab,
  },
  data() {
    return {
      artist: null,
      loading: true,
      model: null,
      tab: null,
    };
  },
  created: async function () {
    this.tab = sessionStorage.getItem("currentTab");
    const artistCode = this.$route.params.artistCode;
    const res = await ArtistApi.loadArtist(artistCode);
    this.model = res.data;
    this.artist = res.data.artist;
    // this.products = res.data.products
    // this.retailes = res.data.retailers
    this.loading = false;
  },
  computed: {
    title () {
      return this.artist ? `${this.artist.name} | Artwork, products and merchandise` : ''
    }
  },
  destroyed: function () {
    sessionStorage.removeItem("currentTab");
  },
  methods: {
    handleChange(index) {
      sessionStorage.setItem("currentTab", index);
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
.stickyHeader {
  background-color: white;
  position: sticky;
  top: 112px;
  width: 100%;
  z-index: 9;
}
</style>
